import {createSelector} from 'reselect';
import moment from 'moment';
import {
    i18n,
    InjectorHelper,
    MetadataConstants,
    MetadataSelectors,
    SessionSelectors,
    SelectHelper
} from 'invision-core';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {getFormattedServiceAttributeValue} from './services.list.selectors.helper';
import {MetadataCodeTypeDictionarySelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {
    isEmpty,
    isNil,
    partial,
    pathOr,
    values
} from 'ramda';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {RouteParams,
    RoutePreviousState,
    SelectedCustomerSelector} from './customer.selectors';
import {PaymentInstrumentsSelector} from './../../reducers/selectors/customer.ewallet.selectors';
import LocaleKeys from './../../locales/keys';
import {
    SUBSCRIPTION_DETAILS_ROUTE
} from '../../components/customer/subscriptions/subscriptions.config';
import {IsTransactionReceiptTaxBreakoutEnabledSelector} from './search.selectors';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';


const EMPTY_ARRAY = [];

const RecoverableTransactionsSelector = (state) => {
    return state.customercare.recoverableUiState.transactions;
};

export const PageNumberSelector = createSelector(
    [RecoverableTransactionsSelector],
    (uiState) => {
        return uiState.pageNumber ? uiState.pageNumber : 0;
    }
);

const TransactionFilterData = createSelector(
    [RecoverableTransactionsSelector],
    (recoverableInteraction) => {
        return recoverableInteraction.filterData;
    }
);

export const HasSelectedTransactionFilters = createSelector(
    [TransactionFilterData],
    (transactionFilters) => {
        return values(transactionFilters)
            .some((item) => {
                return !(isNil(item) || isEmpty(item));
            });
    }
);

export const PaymentInstrumentIdUrlSearchParamSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.paymentInstrumentId;
    }
);

export const BrandableCurrencySelector = createSelector(
    [TransactionFilterData],
    (filterData) => {
        return filterData.brandableCurrency;
    }
);

export const CurrencySelector = createSelector(
    [TransactionFilterData],
    (filterData) => {
        return filterData.currency;
    }
);

export const EndDateSelector = createSelector(
    [TransactionFilterData],
    (filterData) => {
        return filterData.endDate;
    }
);

export const LockerItemIdSelector = createSelector(
    [TransactionFilterData],
    (filterData) => {
        return filterData.lockerItemId;
    }
);

export const PaymentInstrumentSelector = createSelector(
    [TransactionFilterData],
    (filterData) => {
        return filterData.paymentInstrument;
    }
);

export const PreviousRouteSelector = createSelector(
    [RouteParams],
    (params) => {
        return params.previousRoute;
    }
);

export const StartDateSelector = createSelector(
    [TransactionFilterData],
    (filterData) => {
        return filterData.startDate;
    }
);

export const TransactionResultSelector = createSelector(
    [TransactionFilterData],
    (filterData) => {
        return filterData.transactionResult;
    }
);

export const TransactionTypesSelector = createImmutableSelector(
    [TransactionFilterData],
    (filterData) => {
        return filterData.transactionTypes;
    }
);

export const IsFetchingDataSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.transactions.isFetchingData;
    }
);

export const TransactionErrorSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.transactions.transactionsError;
    }
);

export const RecordCountSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.transactions.recordCount ? selectedCustomer.transactions.recordCount : 0;
    }
);

export const EndRecordSelector = createSelector(
    [PageNumberSelector, SessionSelectors.PageSizePreferenceSelector, RecordCountSelector],
    (pageNumber, pageSize, recordCount) => {
        const endNumber = (pageNumber * pageSize);

        if (endNumber < recordCount) {
            return endNumber;
        }

        return recordCount;
    }
);

export const StartRecordSelector = createSelector(
    [PageNumberSelector, SessionSelectors.PageSizePreferenceSelector, RecordCountSelector],
    (pageNumber, pageSize, recordCount) => {
        if (recordCount <= 0) {
            return 0;
        }

        return ((pageNumber - 1) * pageSize) + 1;
    }
);

export const TransactionDataSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.transactions.data;
    }
);

export const IsEligibleForPartialRefundsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.transactions.AllowPartialRefunds;
    }
);

export const ProductsDataSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.transactions.Products;
    }
);

export const PricingPlansDataSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.transactions.PricingPlans;
    }
);

export const OfferingsDataSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.transactions.Offerings;
    }
);

export const CanRetryTransactionSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.transactions.canRetryTransaction || false;
    }
);

export const CurrentTransactionIdSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.transactionId;
    }
);

export const CurrentTransactionFromSearchSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.fromTransactionSearch || false;
    }
);

const getFormattedTransactionDetails = (details) => {
    if (details && details.PaymentAmounts) {
        return details
            .set('paymentAmounts', details.PaymentAmounts.map((item) => {
                if (details.BrandableCurrency) {
                    return item.setIn(['PaymentInstrument', 'StoredValueAccount', 'BrandableCurrencyName'], details.BrandableCurrencyName);
                } else {
                    return item;
                }
            }))
            .set('paymentInstruments', details.PaymentAmounts.map(({PaymentInstrument}) => {
                return PaymentInstrument;
            }));
    }
    return details;
};

export const CurrentTransactionDetailsSelector = createSelector(
    [CurrentTransactionIdSelector, TransactionDataSelector],
    (transactionId, transactions) => {
        return transactionId && transactions && transactions[transactionId] ?  getFormattedTransactionDetails(transactions[transactionId].details) : null;
    }
);

/// Changed by ASC-95425 to force header label change for more generic
const changeTransactionChargeName = (Type, TypeName) => {
    switch (Type) {
        case 31:
            return i18n.translate(LocaleKeys.TRANSACTION_TYPE.PAYMENT);
        default:
            return TypeName;
    }
};

const getMappedPurchaseOrderItems = ({Currency, BrandableCurrencyName, Items}, products, pricingPlans, offerings, regularExpressionCodeTable, serviceAttributeCodeTable) => {


    return Items.map((item) => {
        return {
            label: changeTransactionChargeName(item.Type, item.TypeName),
            id: item.Id,
            attemptedTotal: item.AttemptedTotalAmount,
            transactedTotal: item.TransactedTotalAmount,
            currency: Currency,
            brandableCurrencyName: BrandableCurrencyName,
            creditableAmount: item.CreditableAmount,
            formattedServiceIdentifier:
                item.PurchaseInformation && item.PurchaseInformation.ServiceIdentifier ?
                    getFormattedServiceAttributeValue(item.PurchaseInformation.ServiceIdentifier.ServiceAttributeId,
                        item.PurchaseInformation.ServiceIdentifier.Value,
                        serviceAttributeCodeTable,
                        regularExpressionCodeTable)
                    : undefined,
            offerId: item.PurchaseInformation ? item.PurchaseInformation.OfferingId: undefined,
            offerName: (offerings && item.PurchaseInformation && item.PurchaseInformation.OfferingId
                && offerings[item.PurchaseInformation.OfferingId])
                ? offerings[item.PurchaseInformation.OfferingId].Name : undefined,
            pricingPlanId: item.PurchaseInformation ? item.PurchaseInformation.PricingPlanId : undefined,
            pricingPlanName: (item.PurchaseInformation && item.PurchaseInformation.PricingPlanId
                && pricingPlans[item.PurchaseInformation.PricingPlanId])
                ? pricingPlans[item.PurchaseInformation.PricingPlanId].Name : undefined,
            productId: item.PurchaseInformation ? item.PurchaseInformation.ProductId : undefined,
            productName: (item.PurchaseInformation && item.PurchaseInformation.ProductId
                && products[item.PurchaseInformation.ProductId])
                ? products[item.PurchaseInformation.ProductId].Name : undefined,
            purchaseInformation: item.PurchaseInformation,
            tableData: [{
                description: item.Description,
                attempted: item.AttemptedAmount,
                transacted: item.TransactedAmount,
                type: changeTransactionChargeName(item.Type, item.TypeName),
                date: item.Created,
                currency: Currency,
                brandableCurrency: BrandableCurrencyName
            }].concat((item.AppliedItems || []).map((applied) => {
                return {
                    description: applied.Description,
                    attempted: applied.AttemptedAmount,
                    transacted: applied.TransactedAmount,
                    type: changeTransactionChargeName(applied.Type, applied.TypeName),
                    date: applied.Created,
                    currency: Currency,
                    brandableCurrency: BrandableCurrencyName
                };
            }))
        };
    });
};
export const CurrentTransactionDetailsPurchaseOrderItemsSelector = createSelector(
    [CurrentTransactionDetailsSelector,
        ProductsDataSelector,
        PricingPlansDataSelector,
        OfferingsDataSelector,
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression),
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute)],
    (transactionDetails, products, pricingPlans, offerings, regularExpressionCodeTable, serviceAttributeCodeTable) => {
        return transactionDetails && transactionDetails.PurchaseOrder ?
            getMappedPurchaseOrderItems(transactionDetails.PurchaseOrder, products, pricingPlans, offerings, regularExpressionCodeTable, serviceAttributeCodeTable) :
            EMPTY_ARRAY;
    }
);

export const MutableTransactionDetailPaymentInstruments = createSelector(
    [CurrentTransactionDetailsSelector],
    (transactionDetails) => {
        return transactionDetails && transactionDetails.paymentInstruments ? transactionDetails.paymentInstruments.asMutable({
            deep: true
        }) : EMPTY_ARRAY;
    }
);

export const TransactionsSelector = createSelector(
    [TransactionDataSelector],
    (transactionData) => {
        return values(transactionData).sort((a, b) => {
            return moment(a.Created).isBefore(moment(b.Created)) ? 1 : -1;
        });
    }
);

export const TransactionDetailsSelector = createSelector(
    [TransactionDataSelector, CurrentTransactionIdSelector],
    (transactions, currentTransactionId) => {
        return transactions[currentTransactionId];
    }
);

const toCurrencyForPropertyOnTotals = (details, prop) => {
    const $filter = InjectorHelper.getFilterService();
    const amount = details.BrandableCurrencyName
        ? `${details.PurchaseOrder.Totals[prop]} ${details.BrandableCurrencyName}`
        : $filter('invCurrency')(details.PurchaseOrder.Totals[prop], details.PurchaseOrder.Currency, undefined, undefined, true);
    return numberFormat(details.PurchaseOrder.Totals[prop], amount);
};

const mapPurchaseOrderItems = partial((Currency, BrandableCurrencyName, $filter, {Created, Description, TransactedTotalAmount, TypeName}) => {
    return {
        Created: $filter('date')(Created, 'short'),
        Description,
        TransactedTotalAmount: numberFormat( TransactedTotalAmount,
            BrandableCurrencyName
                ? `${TransactedTotalAmount} ${BrandableCurrencyName}`
                : $filter('invCurrency')(TransactedTotalAmount, Currency, undefined, true, true)),
        TypeName
    };
});

const numberFormat = (flatAmount, amount) => {
    // eslint-disable-next-line no-useless-escape
    const symbol = amount.replace(/[\d\-\.\s]/gmi, '');
    return flatAmount < 0 ? `${symbol}${flatAmount}` : amount;
};

// https://qa-documentation.ascendon.tv/v17.4/data.aspx?type=ContentDirect.CoreService.Contract.Data.SubscriberManagement.PurchaseOrderItemType&assembly=ContentDirect.CoreService.Contract
// Taxes are hidden from the receipt (as they are displayed as a sum value)
const CREDIT_TYPES = {
    COUPON_DISCOUNT: 24,
    CREDIT: 2,
    DISCOUNT: 8,
    DISCRETIONARY_DISCOUNT: 21,
    EPISODIC_PURCHASE_DISCOUNT: 30,
    GLOBAL_DISCOUNT: 25,
    INSTANT_DISCOUNT: 26,
    PRICING_PLAN_DISCOUNT: 22,
    PRODUCT_PURCHASE_DISCOUNT: 23,
    QUANTITY_DISCOUNT: 29,
    REFUND: 3,
    SHIPPING_AND_HANDLING_DISCOUNT: 27,
    WRITE_OFF: 20
};
const PURCHASE_ITEMS = {
    A_LA_CARTE_PURCHASE: 31,
    BALANCE_ACCOUNT_CHARGE: 15,
    BALANCE_ACCOUNT_PURCHASE: 14,
    GIFT_CARD_PURCHASE: 13,
    LATE_FEE: 17,
    ONE_TIME_INSTANT_PURCHASE: 1,
    ONE_TIME_RENEWAL_PURCHASE: 9,
    PRORATED_PURCHASE: 18,
    SHIPPING_AND_HANDLING: 16,
    SUBSCRIPTION_INSTANT_PURCHASE: 11,
    SUBSCRIPTION_RENEWAL_PURCHASE: 12,
    TERMINATION_FEE: 10
};

const TAX_TYPES = {
    COUNTY_TAX: 6,
    FEDERAL_TAX: 4,
    LOCAL_TAX: 7,
    STATE_TAX: 5
};

const CREDIT_TYPE_VALUES = values(CREDIT_TYPES);
const PURCHASE_ITEMS_VALUES = values(PURCHASE_ITEMS);
const TAX_TYPES_VALUES = values(TAX_TYPES);

export const PrintTransactionDetailsSelector = createSelector(
    [
        TransactionDataSelector,
        CurrentTransactionIdSelector,
        IsTransactionReceiptTaxBreakoutEnabledSelector
    ],
    (transaction, id, isTaxBreakOutEnabled) => {
        if (transaction && transaction[id] && transaction[id].details && transaction[id].ReceiptContext) {
            const {details, ReceiptContext} = transaction[id];
            const $filter = InjectorHelper.getFilterService();
            const mapPurchaseOrderItemsWithCurrency = mapPurchaseOrderItems([details.Currency, details.BrandableCurrencyName, $filter]);
            const flattenedPurchasedOrderItems = details.PurchaseOrder ? details.PurchaseOrder.Items.reduce((prev, {AppliedItems, Created, Description, TransactedAmount, Type, TypeName}) => {
                let toAdd = [];
                let appliedItems = AppliedItems || [];
                if (PURCHASE_ITEMS_VALUES.includes(Type)) {
                    toAdd.push({
                        Created,
                        Description,
                        isTopLevel: true, // Each item has a "top level" item, and applied items that need to be flattened
                        TransactedTotalAmount: TransactedAmount,
                        TypeName
                    });
                }
                if (isTaxBreakOutEnabled) {
                    appliedItems = appliedItems.map((item) => {
                        return {
                            Created: item.Created,
                            Description: item.Description,
                            TransactedTotalAmount: item.TransactedAmount !== undefined ? convertStringToNumber(item.TransactedAmount.toFixed(2)) : item.TransactedAmount,
                            Type: item.Type,
                            TypeName: item.TypeName,
                            isCreditTax: item.TransactedAmount < 0
                        };
                    });
                }

                toAdd = toAdd.concat(appliedItems);
                return prev.concat(toAdd);
            }, []) : [];
            return {
                AuthorizationCode: details.ResultCode,
                BusinessUnit: ReceiptContext.BusinessUnitName,
                BusinessUnitAddress: ReceiptContext.BusinessUnitAddress,
                Items: flattenedPurchasedOrderItems
                    .filter(({isTopLevel, Type, isCreditTax}) => {
                        return isTopLevel || PURCHASE_ITEMS_VALUES.includes(Type) || (isTaxBreakOutEnabled && TAX_TYPES_VALUES.includes(Type) && !isCreditTax);
                    })
                    .map(mapPurchaseOrderItemsWithCurrency),
                CreditItems: flattenedPurchasedOrderItems
                    .filter(({Type, isCreditTax}) => {
                        return CREDIT_TYPE_VALUES.includes(Type) || isCreditTax;
                    })
                    .map(mapPurchaseOrderItemsWithCurrency),
                Email: ReceiptContext.Subscriber.Email,
                Name: {
                    First: ReceiptContext.Subscriber.FirstName,
                    Last: ReceiptContext.Subscriber.LastName,
                    CompanyName: ReceiptContext.Subscriber.CompanyName
                },
                OrderNumber: details.PurchaseOrder ? details.PurchaseOrder.OrderNumber : null,
                PaymentAmounts: details.PaymentAmounts ? details.PaymentAmounts.map(({PaymentInstrument}) => {
                    return Object.assign({
                        name: ''
                    }, PaymentInstrument);
                }) : [],
                Totals: details.PurchaseOrder ? {
                    DiscountAmount: toCurrencyForPropertyOnTotals(details, 'DiscountAmount'),
                    OriginalTotalAmount: toCurrencyForPropertyOnTotals(details, 'OriginalTotalAmount'),
                    ReturnTaxAmount: toCurrencyForPropertyOnTotals(details, 'ReturnTaxAmount'),
                    SubTotalAmount: toCurrencyForPropertyOnTotals(details, 'SubTotalAmount'),
                    TaxAmount: toCurrencyForPropertyOnTotals(details, 'TaxAmount'),
                    TotalAmount: toCurrencyForPropertyOnTotals(details, 'TotalAmount'),
                    TotalReturnAmount: toCurrencyForPropertyOnTotals(details, 'TotalReturnAmount')
                } : null,
                TransactionDate: ReceiptContext.TransactionDate,
                TransactionId: details.Id
            };
        } else {
            return null;
        }
    }
);

export const HasLoadedTransactions = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.transactions.hasLoadedTransactions;
    }
);

export const TableDataSelector = createSelector(
    [HasLoadedTransactions, TransactionsSelector],
    (hasLoadedTransactions, transactions) => {
        if (hasLoadedTransactions) {
            const $filter = InjectorHelper.getFilterService();

            return transactions.map((transaction) => {
                const dateEval = transaction.Created;
                const amountEval = transaction.OriginalAmount;
                const purchasedProducts = transaction.PurchasedProducts;
                return transaction
                    .set('Created', dateEval ? $filter('localShort')(dateEval) : null)
                    .set('OriginalAmount', amountEval && transaction.Currency ? $filter('invCurrency')(amountEval, transaction.Currency) : null)
                    .set('PurchasedProducts', purchasedProducts ? purchasedProducts : i18n.translate(LocaleKeys.NOT_APPLICABLE));
            });
        }

        return EMPTY_ARRAY;
    }
);

export const TotalPagesSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.transactions.pageCount;
    }
);

export const SelectedPageSizePreference = createSelector(
    [RecoverableTransactionsSelector],
    (uiState) => {
        return uiState.filterData.pageSizePreference;
    }
);

const BrandableCurrencyCodes = (state) => {
    return MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.BrandableCurrency, state);
};

export const BrandableCurrencyCodeOptionsSelector = createSelector(
    [BrandableCurrencyCodes, BrandableCurrencySelector],
    (brandableCurrencyCodes, selectedBrandableCurrency) => {
        const formattedCodes = [];

        brandableCurrencyCodes.forEach((code) => {
            const formattedCode = SelectHelper.codeValueToSelectOption(code);
            const selectedValue = selectedBrandableCurrency ? selectedBrandableCurrency.value : null;
            formattedCode.selected = formattedCode.value === selectedValue;
            formattedCodes.push(formattedCode);
        });

        return formattedCodes;
    }
);

const CurrencyCodes = (state) => {
    return MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.Currency, state);
};

export const CurrencyCodeOptionsSelector = createSelector(
    [CurrencyCodes, CurrencySelector],
    (currencyCodes, selectedCurrency) => {
        const formattedCodes = [];

        currencyCodes.forEach((code) => {
            const formattedCode = SelectHelper.codeValueToSelectOption(code);
            const selectedValue = selectedCurrency ? selectedCurrency.value : null;
            formattedCode.selected = formattedCode.value === selectedValue;
            formattedCodes.push(formattedCode);
        });

        return formattedCodes;
    }
);

export const TransactionFilterPaymentInstrumentSelector = createSelector(
    [PaymentInstrumentsSelector],
    (paymentInstruments) => {
        return paymentInstruments.map((paymentInstrument) => {
            if (paymentInstrument.StripeAccount) {
                return paymentInstrument.set('Name', `${paymentInstrument.TypeName} ${paymentInstrument.StripeAccount.PaymentMethodType ? ` - ${paymentInstrument.StripeAccount.PaymentMethodType}` : ''} ${paymentInstrument.StripeAccount.PaymentMethodSubType ? ` - ${paymentInstrument.StripeAccount.PaymentMethodSubType}` : ''}`);
            }
            return paymentInstrument.set('Name', `${paymentInstrument.TypeName}: ${paymentInstrument.Name}`);
        });
    }
);

export const PaymentInstrumentOptionsSelector = createSelector(
    [TransactionFilterPaymentInstrumentSelector, PaymentInstrumentSelector],
    (paymentInstruments, selectedPaymentInstrument) => {
        const formattedEntities = [];

        paymentInstruments.forEach((entity) => {
            const formattedEntity = SelectHelper.entityValueToSelectOption(entity);
            const selectedValue = selectedPaymentInstrument ? selectedPaymentInstrument.value : null;
            formattedEntity.selected = formattedEntity.value === selectedValue;
            formattedEntities.push(formattedEntity);
        });

        return formattedEntities;
    }
);

const IsPreviousSubscriptionRouteSelector = createSelector(
    [RoutePreviousState],
    (route) => {
        return route.name === SUBSCRIPTION_DETAILS_ROUTE;
    }
);

const IsPreviousTransactionDetailRouteSelector = createSelector(
    [RoutePreviousState],
    (route) => {
        return route.name.match('index.customercare.customer.transactions') !== null;
    }
);

const ShowBackBannerSelector = createSelector(
    [RecoverableTransactionsSelector],
    (uiState) => {
        return uiState.showBanner;
    }
);

export const ShowSubscriptionBackBannerSelector = createSelector(
    [IsPreviousSubscriptionRouteSelector,
        IsPreviousTransactionDetailRouteSelector,
        ShowBackBannerSelector],
    (isPreviousSubscriptionRoute,
        isPreviousTransactionDetailRoute,
        showBackBanner) => {
        return showBackBanner && (isPreviousSubscriptionRoute || isPreviousTransactionDetailRoute);
    }
);

export const SubscriptionSelector = createSelector(
    [RouteParams],
    (params) => {
        return params.subscription;
    }
);

export const SelectedPaymentIdFromRouteSelector = createSelector(
    [RouteParams],
    (params) => {
        return params.selectedPaymentId;
    }
);

export const IsSendingEmailSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(false, ['transactions', 'isSendingEmail'], selectedCustomer);
    }
);
